$primary-color: #283686;
$secondary-color: #3a3a3a;
$gray: #555;
$light-gray: #f8f9fa;
$success-color: #34ab45;
$hover: #e98a1d;
$btn: #c9cde1;
$blue: #00a1fb;

$orange-color: #e98a1d;
$light-gray-color: #9ea0a4;
$search-box: #eeeeee;
$white: #fff;
$black: #111;

$background: red;
$bg-light-gray: #f8f9fa;
$bg-light-purple-hover: #8c96d6;

$heading-one: 5.2rem;
$heading-two: 3.6rem;
$heading-three: 2.6rem;

$para-one: 3rem;
$para-two: 1.8rem;
$para-three: 1.6rem;

$input-height: 4rem;
