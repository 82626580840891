@import "./mixin.module.scss";
@import "./partials.scss";

.main_container {
  min-width: 100%;
  max-width: 100%;
  min-height: 100vh;
  max-height: 100%;
  background-color: $light-gray;
}
.sidebar {
  position: fixed;
  width: 280px;
  top: 0;
  left: 0;
  background-color: $light-gray;
  height: 100%;
  transition: 0.5s;
  padding: 12px 10px 0px 10px;
  z-index: 999;

  .sidebar_wrapper {
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    border-radius: 10px 10px 0px 0px;

    // SideBar Top

    .sidebartop {
      display: flex;
      align-items: center;
      padding: 20px 5px 20px 20px;
      // border-bottom: 0.5px solid #ddd;

      .sidebarLogo {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 100%;
        height: 100%;
        white-space: nowrap;
      }
      span {
        font-size: 1.2rem;
        font-weight: 600;
        color: $white;
        white-space: nowrap;
      }
    }

    // SideBar Bottom

    .sidebarbottom {
      display: flex;
      flex-direction: column;
      color: white;
      margin-top: 20px;

      .sidebarmenu {
        list-style: none;

        a {
          margin: 3px 0px;
          padding: 5px 12px;
          display: flex;
          justify-content: space-between;
          transition: background-color 0.2s;

          &:hover {
            background: rgb(45, 51, 89);
            border-left: 3px solid $white;
          }
          li {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: $white;
          }

          .sidebarIcon {
            margin: 0px 10px;
          }

          .sidebarText {
            font-size: 1rem;
          }
          .dropdownIcon {
            font-size: 32px;
          }
        }
      }
    }
  }
}
.main {
  position: absolute;
  width: calc(100% - 300px);
  left: 300px;
  transition: 0.5s;
  background-color: $light-gray;
}
.Navbar {
  width: 100%;
  background-color: $white;
  padding: 20px;
  cursor: pointer;
}
/* Active */
.sidebarActive {
  width: 80px;

  .sidebartop > span {
    transform: 0.2s;
    display: none;
  }
  .sidebartop {
    padding: 20px 0px 20px 15px !important;
  }
  .sidebarIcon {
    margin: 0px 10px;

    svg {
      font-size: 1.9rem;
    }
  }
  .sidebarText {
    position: absolute;
    background-color: $primary-color;
    padding: 6px;
    border-radius: 5px;
    left: 95%;
    opacity: 0;
    display: none;
  }
  .sidebarmenu > li {
    padding: 5px;
  }
}
.sidebarActive .dropdownIcon {
  display: none;
}
.sidebarActive .sidebarmenu > li:hover .sidebarText {
  opacity: 1;
  display: block;
}

.mainActive {
  width: calc(100% - 100px);
  left: 100px;
}
.navbarActive {
  background: rgb(45, 51, 89);
  border-left: 3px solid $white;
}
.notActive{
  background:none;
  border-left: none ;
}
@media screen and (max-width: 900px) {
  .sidebar {
    left: -300px;
  }
  .sidebarActive {
    left: 0px;
    width: 280px !important;

    .sidebartop > span {
      display: block;
    }
    .sidebartop {
      padding: 20px 5px 20px 20px !important;
    }

    .sidebarText {
      position: relative;
      background-color: transparent;
      padding: 6px;
      left: 0;
      opacity: 100;
      display: block;
    }

    .sidebarIcon svg {
      font-size: 2rem;
    }
    .sidebarmenu > li {
      padding: 0px;
    }
    .dropdownIcon {
      display: block;
    }
  }
  .main {
    width: 100%;
    left: 0;
  }
  .mainActive {
    left: 300px !important;
  }
  .taskProfile > h6 {
    display: block;
  }
}