@import "./mixin.module.scss";
@import "./partials.scss";

.dashboard {
  width: 100%;
  min-height: 100vh;
  max-height: 100%;
  background-color: $light-gray;

  .dashboard_wrapper {
    padding: 10px 10px;
  }

  // DashBoard Top Start

  .employes {
    @include allCenter;
    width: 100%;
    gap: 10px;
    margin-bottom: 20px;

    .employes_left {
      flex: 3;
      background-color: $white;
      box-shadow: 0px 0px 5px 1px rgba(85, 5, 0, 0.3);
      border-radius: 6px;
      height: 250px;

      .line_chart {
        height: 100% !important ;

        canvas {
          height: 100% !important ;
          width: 100% !important;
        }
      }
    }
    .employes_right {
      flex: 1;
      background-color: $primary-color;
      height: 250px;
      border-radius: 6px;
      @include allCenter;
      flex-direction: column;
      gap: 5px;
      color: $white;

      .employes_right_logo {
        @include allCenter;
        width: 80px;
        height: 80px;
        background-color: $white;
        border-radius: 50%;

        svg {
          color: $primary-color;
          font-size: 3.5rem;
        }
      }
      h3 {
        font-size: 2rem;
        font-weight: 600;
        margin-top: 10px;
      }
      h5 {
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }
  .employes_boxs {
    @include verticallycenter;
    width: 100%;
    gap: 15px;
    margin-bottom: 20px;
    flex-wrap: wrap;

    .employes_box {
      flex: 1;
      background-color: $white;
      height: 100%;
      border-radius: 5px;
      padding: 16px 20px;
      box-shadow: 0px 0px 5px 1px rgba(85, 5, 0, 0.3);
      @include horizontallysapce;

      .employes_box_left {
        width: 60px;
        height: 60px;
        background-color: $btn;
        border-radius: 50%;
        margin-right: 10px;

        @include allCenter;

        svg {
          color: $primary-color;
          font-size: 2.5rem;
        }
      }
      .employes_box_right {
        text-align: center;
        margin-left: 10px;

        h4 {
          font-size: 1.6rem;
          margin-bottom: 10px;
        }
        h5 {
          font-size: 0.9rem;
          color: $gray;
        }
      }
    }
  }
  // DashBoard Top End

  // DashBoard Bottom Start

  .employes_detail {
    @include allCenter;
    width: 100%;
    gap: 10px;
    margin-bottom: 20px;

    .employes_detailLeft {
      flex: 2.5;
      background-color: $white;
      box-shadow: 0px 0px 5px 1px rgba(85, 5, 0, 0.3);
      height: 270px;

      .employes_detailLeft_Wrapper {
        padding: 12px;

        .detailLeft_heading {
          color: $gray;
          margin-left: 5px;
          font-size: 1rem;
        }
        .detailLeft_boxs {
          width: 100%;
          @include verticallycenter;
          justify-content: space-evenly;
          gap: 10px;
          flex-wrap: wrap;
          margin-top: 20px;

          .detailLeft_box {
            @include horizontallysapce;
            width: 40%;
            background-color: $white;
            box-shadow: 0px 0px 5px 1px rgba(85, 5, 0, 0.3);
            padding: 20px 20px;

            .detailLeft_logo {
              width: 50px;
              height: 50px;
              background-color: $btn;
              border-radius: 50%;
              @include allCenter;
              svg {
                color: $primary-color;
                font-size: 2rem;
              }
            }
            .detailLeft_Dec {
              text-align: right;

              h5 {
                font-size: 1rem;
                color: $gray;
                font-weight: 600;
                margin-bottom: 6px;
              }
              h3 {
                font-size: 1.8rem;
              }
            }
          }
        }
      }
    }
    .employes_detailRight {
      flex: 1.5;
      background-color: $white;
      box-shadow: 0px 0px 5px 1px rgba(85, 5, 0, 0.3);
      height: 100%;
      // height: 260px;
      height: 270px;
      z-index: 999;
      @include allCenter;

      .PieChar {
        height: 270px;

        canvas {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }

  // DashBoard Bottom End

  // DashBoard Footer Start

  .statistics {
    @include allCenter;
    width: 100%;
    gap: 10px;
    margin-bottom: 20px;

    .statistics_left {
      flex: 2;
      background-color: $white;
      box-shadow: 0px 0px 5px 1px rgba(85, 5, 0, 0.3);
      height: 470px;
      .statistics_progressbars {
        margin-top: 10px;
        .statistics_progressbar {
          width: 100%;
          box-shadow: 0px 0px 3px 1px rgba(85, 5, 0, 0.3);
          margin: 12px 0px;

          .statistics_progressbar_details {
            @include horizontallysapce;
            padding: 15px 20px;

            span {
              font-size: 1rem;
              font-weight: 600;
            }
          }
          .progessbar {
            padding: 0px 20px 10px;

            .bars {
              position: relative;
              width: 100%;
              height: 6px;
              background-color: #eee;
              border-radius: 10px;
              .bar {
                position: absolute;
                height: 100%;
                background-color: $primary-color;
                width: 40%;
                border-radius: 10px;
              }
            }
          }
        }
      }
    }
    .statistics_right {
      flex: 2;
      background-color: $white;
      box-shadow: 0px 0px 5px 1px rgba(85, 5, 0, 0.3);
      height: 470px;

      .taskBar {
        width: 100%;

        .taskbar_top {
          @include horizontallysapce;

          .taskbar_top_left,
          .taskbar_top_right {
            width: 38%;
            padding: 30px 20px;
            background-color: $light-gray;
            box-shadow: 0px 0px 3px 1px rgba(85, 5, 0, 0.3);
            @include horizontallysapce;

            span:nth-child(1) {
              font-size: 1rem;
              font-weight: 900;
              color: $gray;
              white-space: nowrap;
              margin-right: 5px;
            }
            span:nth-child(2) {
              font-size: 1.5rem;
              font-weight: 600;
            }
          }
        }
        .taskbar_center {
          width: 100%;
          background-color: $primary-color;
          border-radius: 6px;
          height: 26px;
          margin: 20px 0px;
          @include allCenter;

          div {
            width: 25%;
            height: 100%;
            border-radius: 0px 3px 3px 0px;
            color: $white;
            @include allCenter;
          }
        }
        .taskbar_bottom {
          @include horizontallysapce;
          padding: 0px 10px;

          .radio {
            @include verticallycenter;
            margin: 10px 0px;

            .radio_btn {
              position: relative;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              margin-right: 10px;
              cursor: pointer;
              @include allCenter;

              .btn {
                position: absolute;
                width: 60%;
                height: 60%;
                border-radius: 50%;
              }
            }
          }
          span {
            font-size: 1rem;
            font-weight: 600;
          }
        }
      }
    }
    .statistics_Wrapper {
      padding: 30px 20px;

      .statistics_heading {
        font-size: 1.1rem;
        margin-bottom: 20px;
        color: $gray;
      }
    }
  }
}
// DashBoard Footer End

// Responsive

@media screen and (max-width: 1100px) {
  .detailLeft_box {
    width: 37% !important;

    .detailLeft_Dec {
      h5 {
        font-size: 0.8rem !important;
      }
      h3 {
        font-size: 1.5rem !important;
      }
    }
  }
  .employes_box_right {
    h4 {
      font-size: 1.3rem !important;
    }
    h5 {
      font-size: 0.8rem !important;
    }
  }
  .employes_box_left {
    width: 50px !important;
    height: 50px !important;
    svg {
      font-size: 2rem !important;
    }
  }
  .taskbar_top_left,
  .taskbar_top_right {
    span:nth-child(1) {
      font-size: 0.8rem !important;
    }
    span:nth-child(2) {
      font-size: 1.2rem !important;
    }
  }
  .statistics_progressbar_details {
    span:nth-child(1) {
      margin-right: 5px;
    }
    span {
      font-size: 0.9rem !important;
      white-space: nowrap;
    }
  }
}
@media screen and (max-width: 960px) {
  .detailLeft_box {
    width: 35% !important;
    .detailLeft_Dec {
      h5 {
        font-size: 0.7rem !important;
        white-space: nowrap;
      }
      h3 {
        font-size: 1.3rem !important;
      }
    }
    .detailLeft_logo {
      width: 40px !important;
      height: 40px !important;
    }
  }
}
@media screen and (max-width: 890px) {
  .detailLeft_box {
    width: 38% !important;
    .detailLeft_Dec {
      h5 {
        font-size: 1rem !important;
      }
      h3 {
        font-size: 1.5rem !important;
      }
    }
    .detailLeft_logo {
      width: 50px !important;
      height: 50px !important;
    }
  }
}
@media screen and (max-width: 700px) {
  .employes_detail {
    flex-direction: column;
  }
  .statistics {
    flex-direction: column;
    .statistics_left {
      width: 100%;
    }
    .statistics_right {
      width: 100%;
    }
  }

}

@media screen and (max-width: 450px) {
  .detailLeft_box {
    width: 100% !important;
  }
  .taskbar_top {
    flex-direction: column;
    gap: 12px;

    .taskbar_top_left,
    .taskbar_top_right {
      width: 90% !important;
    }
  }
  .PieChar {
    height: initial !important;
  }
}
